export default function FilterHelper(){
  this.init = function(){
    this.model = {
      thSearch: $('th.has-search'),
      formFilter: $('#filter_list_form'),
      maskFilter: null,
    }
    let self = this;

    $('button.btn-reset-filter').on('click', () => {
      this.model.formFilter.submit();
    });

    $('span.icon-filter').on('click', function() {
      let listContainer = $(this).closest('div.listing-container');
      let parent = $(this).closest('th.has-search');
      let currentActive = parent.hasClass('s_active');

      var blockFilterElm = parent.find('.bound-item-fitler');
      var leftPos = parent.offset().left - listContainer.offset().left;
      blockFilterElm.css({left: `${leftPos}px`})

      if (currentActive) return;

      self.model.thSearch.removeClass('s_active');
      parent.addClass('s_active');

      let element = document.getElementById($(this).attr('for'));
      if (element != null && !$(element).hasClass('date_picker')) element.focus();

      self.addMaskFilter();
    })

    $('select#limit_item_per_page').on('change', function(){
      self.model.formFilter.submit();
    })
  }

  this.addMaskFilter = function(){
    if (!this.model.thSearch.hasClass('s_active') || this.model.maskFilter != null)
      return;

    this.model.maskFilter = $('<div class="mask-transparent"></div>');

    $('body').append(this.model.maskFilter);
    this.model.maskFilter.on('click', () => {
      this.model.thSearch.removeClass('s_active');
      this.model.maskFilter.remove();
      this.model.maskFilter = null;
    });
  }
}
