import { Controller } from 'stimulus';
import Swiper, { Autoplay, Pagination, EffectFade, Navigation } from 'swiper';

export default class extends Controller {
  connect() {
    // this.initBannerSwipper();
    this.initResidentStorySwipper();
    this.initBranchBuildingSwipper();
  }

  initBannerSwipper(){
    new Swiper('.banner-swiper-container', {
      modules: [Autoplay, Pagination, EffectFade],
      loop: true,
      effect: 'fade',
      spaceBetween: 30,
      centeredSlides: true,
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
    });
  }

  initBranchBuildingSwipper(){
    new Swiper('.branch-building-swiper', {
      modules: [Pagination, Navigation],
      spaceBetween: 20,
      slidesPerView: 1,
      effect: 'fade',
      slidesPerView: 'auto',
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      breakpoints: {
        640: {
          spaceBetween: 20,
        },
        768: {
          spaceBetween: 20,
        },
        1024: {
          spaceBetween: 30,
        },
      },
    });
  }

  initResidentStorySwipper(){
    new Swiper('.resident-stories-swiper', {
      modules: [Pagination],
      slidesPerView: 1,
      spaceBetween: 30,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      breakpoints: {
        640: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
      },
    });
  }
}
