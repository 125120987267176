import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {}

  hide(){
    $('turbo-frame#side-frame-modal').html('');
    $('body').removeClass('side-frame-open');
  }
}
