import { Controller } from 'stimulus';
import DateTimePicker from '../../../global/helper/date_picker';

export default class extends Controller {
  static targets = [ "fromDate", "district" ];

  connect() {
    this.currentSelectedItem = null;
    this.maskFilter = null;
    let self = this;

    const datePicker = (new DateTimePicker()).init(document.getElementById("check_date_in"));
    datePicker.on("dp.change", function (e) {
      $('#selected_from_date').html(e.date.format('DD/MM/YYYY'));
      self.fromDateTarget.value = e.date.format('YYYY-MM-DD');
    });

    $('span.district-item').on('click', function(e){
      if ($(this).hasClass('active'))
        $(this).removeClass('active')
      else{
        $(this).addClass('active')
      }
      self.syncDistrictTarget();
    })

    $('div.filter-item').on('click', function(e){
      if ($(this).hasClass('opening')){
        self.removeMaskFilter(e.target);
      }
      else{
        $(this).addClass('opening');
        self.removeMaskFilter(null);
        self.currentSelectedItem = $(this);
        self.addMaskFilter();
      }
    })

    $('button.search-btn').on('click', function(e){
      self.removeMaskFilter(e.target);
    })

    this.handleScroll();
  }

  handleScroll(){
    let searchBarElm = $('section.building-search-bar-container');
    let heightTopMenu = 66;
    let positionTop = searchBarElm.offset().top;
    $(window).on('scroll', function() {
      var curScrollTop = $(window).scrollTop();
      if (curScrollTop > positionTop+10)
        searchBarElm.addClass('fixed-top')
      else
        searchBarElm.removeClass('fixed-top')
    });
  }

  addMaskFilter(){
    if (this.maskFilter != null)
      return;

    this.maskFilter = $('<div class="mark-filter base-mark"></div>');
    let self = this;
    $('body').append(this.maskFilter);
    this.maskFilter.on('click', function(e){
      self.removeMaskFilter(e.target);
    });
  }

  removeMaskFilter(target){
    if (target != null && $(target).closest('div.filter-modal-box').length) return;
    if (this.maskFilter == null) return;

    this.currentSelectedItem.removeClass('opening');
    this.maskFilter.remove();
    this.maskFilter = null;
  }

  syncDistrictTarget(){
    let data = [];
    let self = this;
    $('span.district-item.active').each(function() {
      data.push($(this).attr('for'));
    });
    self.districtTarget.value = data.join();
    console.log(data, self.districtTarget.value);
  }
}
