import { Application } from "@hotwired/stimulus"
import { createConsumer } from "@rails/actioncable"
import { definitionsFromContext } from "stimulus/webpack-helpers"

const application = Application.start()

const contextModules = require.context("../modules/managers", true, /\.js$/)
application.load(definitionsFromContext(contextModules))

const context = require.context(".", true, /\.js$/)
application.load(definitionsFromContext(context))
