import { Controller } from 'stimulus';
import Swiper, { Pagination, EffectFade, Navigation } from 'swiper';

export default class extends Controller {
  connect() {
    new Swiper(this.element.querySelector('.swiper-container'), {
      modules: [Pagination, Navigation],
      loop: true,
      spaceBetween: 30,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });
  }
}
