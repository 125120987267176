export default function DateTimePicker(){
  const formatFull = 'DD/MM/YYYY';
  const formatMonth = 'MM/YYYY';

  const icons = {
    time: 'far fa-clock',
    date: 'fa fa-calendar',
    up: 'fa fa-chevron-up',
    down: 'fa fa-chevron-down',
    previous: 'fa fa-chevron-left',
    next: 'fa fa-chevron-right',
    today: 'fa fa-check',
    clear: 'fa fa-trash',
    close: 'fa fa-times'
  }

  this.init = function(obj, clazz = 'jdate'){
    //widgetPositioning: {horizontal: 'auto', vertical: 'bottom'}
    const options = {  locale: 'vi', format: formatFull, icons: icons, useCurrent: false, debug: false};
    if ($(obj).attr('placeholder') == formatMonth){
      options.viewMode = 'months';
      options.format = formatMonth;
    }

    let daysInAdv = $(obj).data('days-in-adv');
    if (daysInAdv != undefined){
      options.maxDate = moment().add(daysInAdv, 'days').endOf('day').toDate();
    }

    let beginingDays = $(obj).data('beginning-days');
    if (beginingDays != undefined){
      options.minDate = moment().add(beginingDays, 'days').startOf('day').toDate();
    }

    let minDate = $(obj).data('min-date');
    if (minDate != undefined){
      options.minDate = moment(minDate).toDate()
    }

    let maxDate = $(obj).data('max-date');
    if (maxDate != undefined){
      options.maxDate = moment(maxDate).endOf('day').toDate()
    }

    let currentMonth = $(obj).data('current-month');
    if (currentMonth != undefined){
      options.minDate = moment().startOf('month').toDate()
      options.maxDate = moment().endOf('day').toDate()
    }

    let inline = $(obj).data('inline');
    if (inline != undefined){
      options.inline = true;
      options.sideBySide = true;
    }

    let defaultDate = $(obj).data('default-date');
    if (defaultDate != undefined){
      options.defaultDate = defaultDate;
    }

    let datePicker = $(obj).datetimepicker(options);
    datePicker.on('dp.show', function() {
      $('body').addClass('dp-show');
    });

    datePicker.on('dp.hide', function() {
      $('body').removeClass('dp-show');
    });
    $(obj).addClass(clazz);
    return datePicker;
  }
}
