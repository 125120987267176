window.ManageApartment = {
  init: function() {
    $(document).on('nested:fieldAdded', () => {
      UIHelper.init();
    });
    $(document).ready(() => {
      this.overrideJQuery();
      this.initUI();
      this.handleTurboEvents();
    });

  },
  initUI: function() {
    UIHelper.init();
  },
  overrideJQuery: function(){
    $.ajaxSetup({
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))}
    });
  },
  handleTurboEvents: function(){
    $(document).on('turbo:frame-render', function(event) {
      UIHelper.init();
      // Turbo.navigator.delegate.adapter.progressBar.hide();
    });

    $(document).on('turbo:click', function(event) {
      if (event.target.getAttribute('data-turbo-frame') == 'side-frame-modal')
        $('body').addClass('side-frame-open');
    });

    document.addEventListener("turbo:before-fetch-request", function(e){
      // Turbo.navigator.delegate.adapter.showProgressBar();
      Cookies.set('prev_page', $('body').data('action'))
    })

    // document.addEventListener("turbo:before-stream-render", function(e){})

    // document.addEventListener("turbo:before-stream-render", function(e){})

    document.addEventListener('turbo:before-fetch-response', function(event) {
      const response = event.detail.fetchResponse;
      if (response.statusCode === 500) {
        toast.error('Request error. Please try again')
      }
    });
  }
}

