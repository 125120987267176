import { Controller } from 'stimulus';
import Swiper, { Autoplay, Pagination, EffectFade, Navigation } from 'swiper';

export default class extends Controller {
  connect() {
    this.initBannerSwipper();
  }

  initBannerSwipper(){
    new Swiper('.banners-swiper', {
      modules: [Navigation],
      slidesPerView: 1,
      spaceBetween: 15,
      loop: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      breakpoints: {
        640: {
          slidesPerView: 1,
          spaceBetween: 15,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 15,
        },
      },
    });
  }
}
