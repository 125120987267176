window.FormHttpRequest = {
  model: {
    submitting: false
  },
  post(path, parameters, method_opt = null, checksubmit = true){
    if (this.model.submitting) return;

    if (checksubmit) this.model.submitting = true;
    const form = $('<form />');
    
    form.attr("method", "post");
    form.attr("action", path);
    $.each(parameters, function(key, item) {
        form.append($('<input name="'+item['name']+'" type="hidden" value="'+item['value']+'" />'));
    });
    if (method_opt != null)
      form.append($('<input name="_method" type="hidden" value="'+method_opt+'" />'));

    $(document.body).append(form);
    form.submit();
  }
}
