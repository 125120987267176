import { Controller } from 'stimulus';
import Swiper, { Pagination } from 'swiper';

export default class extends Controller {
  connect() {
    // Bind checkResponsive to this instance
    this.checkResponsive = this.checkResponsive.bind(this);
    window.addEventListener("resize", this.checkResponsive);
    this.checkResponsive();
  }

  initSwiper() {
    new Swiper('.mobile-swiper', {
      modules: [Pagination],
      loop: true,
      spaceBetween: 15,
      slidesPerView: 1,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      }
    });
  }

  checkResponsive() {
    // Ensure this is bound to the correct context
    if (window.innerWidth <= 767) {
      $('.swiper-slide-react').addClass('swiper-slide');
      $('.swiper-wrapper-react').addClass('swiper-wrapper');
      $('.swiper-wrapper-react').removeClass('row');
      this.initSwiper();
      console.log("This is a mobile device.");
    } else {
      $('.swiper-slide-react').removeClass('swiper-slide');
      $('.swiper-wrapper-react').removeClass('.swiper-wrapper');
      $('.swiper-wrapper-react').addClass('row');
      $('.swiper-wrapper-react').removeAttr( 'style' );
      $('.swiper-slide-react').removeAttr( 'style' );
      console.log("This is not a mobile device.");
    }
  }
}
