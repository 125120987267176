export default function ProvinceHelper(){
  this.init = function(){
    $('select#building_city_id').on('change', function(){
      var id = $(this).val();
      $.ajax({
        url: `/admin/provinces/${id}/districts.js`,
        method: 'GET'
      });
    })

    $('select#building_district_id').on('change', function(){
      var id = $(this).val();
      $.ajax({
        url: `/admin/provinces/${id}/wards.js`,
        method: 'GET'
      });
    })
  }
}
