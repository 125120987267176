import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

export default function FileUploader(){
  $.fn.filepond.registerPlugin(FilePondPluginImagePreview);
  $.fn.filepond.registerPlugin(FilePondPluginFileValidateType);
  $.fn.filepond.setDefaults({
    storeAsFile: true,
  });

  $("input.file").filepond({
    credits: true,
    labelIdle: `Kéo và thả tập tin hoặc <span class="filepond--label-action">Chọn tệp</span>`
  });

  // FilePond.create(
  //   document.querySelector('input.file-uploader'),
  //   {
  //     labelIdle: `Drag & Drop your picture or <span class="filepond--label-action">Browse</span>`,
  //     imagePreviewHeight: 170,
  //     imageResizeTargetWidth: 200,
  //     imageResizeTargetHeight: 200,
  //     styleLoadIndicatorPosition: 'center bottom',
  //     styleProgressIndicatorPosition: 'right bottom',
  //     styleButtonRemoveItemPosition: 'right top',
  //     styleButtonProcessItemPosition: 'right bottom',
  //     imageCropAspectRatio: 1,
  //     allowImageCrop: true,
  //     stylePanelLayout: 'compact',
  //     // resize to width of 200
  //     imageResizeTargetWidth: 200,
  //   }
  // );
}
