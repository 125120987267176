export default function NumberInputHelper(){
  this.init = function(){
    let self = this;

    $("input.number:not(.jnumber)").each(function(){
      $(this).parent().append(`<input class='reflect_number' type='hidden' name="${$(this).attr('name')}" value="${self.clearFormatNumber($(this).val())}" />`);
      $(this).attr('name', '');
      $(this).addClass('jnumber')
      $(this).on({
        keyup: function() {
          self.formatCurrency(this);
        },
        blur: function() {
          self.formatCurrency(this);
        }
      });
    })

  }

  this.formatNumber = function(n){
    return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }

  this.clearFormatNumber = function(n){
    return n.replace(/,/g, '')
  }

  this.formatCurrency = function(input){
    input.value = this.formatNumber(input.value);
    $(input).parent().find('input.reflect_number').val(this.clearFormatNumber(input.value))
  }
}




