import "@hotwired/turbo-rails";
import moment from 'moment/dist/moment';
import jQuery from 'jquery';
import * as bootstrap from 'bootstrap';
import * as FilePond from 'filepond';
import lightbox from 'lightbox2';
import simpleLightbox from 'simplelightbox';
import TurboPower from 'turbo_power';
import ToastHelper from 'global/helper/toast_helper';
import Cookies from 'js-cookie';

TurboPower.initialize(Turbo.StreamActions);

window.Trix = require("trix");
window.$ = window.jQuery = jQuery;
window.moment = moment;
window.bootstrap = bootstrap;
window.lightbox = lightbox;
window.FilePond = FilePond;
window.toast = new ToastHelper();
window.simpleLightbox = simpleLightbox;
window.Cookies = Cookies;

require("@rails/ujs").start()
require('jquery-filepond/filepond.jquery');
require("@rails/actiontext")
require("jquery_nested_form")
require("vendor/bootstrap-select")
require("vendor/bootstrap-datetimepicker.min")
require("global/ui_helper")


Turbo.StreamActions.toast = function() {
  var text = this.getAttribute("text");
  if (this.getAttribute("style") == 'error')
    toast.error(text)
  else
    toast.success(text)
}
