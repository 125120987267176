import DateTimePicker from './helper/date_picker';
import FilterHelper from './helper/filter_helper';
import ApartmentHelper from './helper/apartment_helper';
import ProvinceHelper from './helper/province_helper';
import LoadInfinityHelper from './helper/load_infinity_helper';
import NumberInputHelper from './helper/number_input_helper';
import FileUploader from './helper/file_uploader';
import SwiperTabs from './helper/swiper_tabs';
require("./helper/form_http_request");


window.UIHelper = {
  init() {
    this.initSelectPicker();
    this.initDatePicker();
    this.initShowPassword();
    this.initLightBox();
    this.initModal();
    this.initHandleCloseModal();
    this.initTooltip();
    FileUploader();
    SwiperTabs();

    (new ApartmentHelper()).init();
    (new ProvinceHelper()).init();
    (new FilterHelper()).init();
    (new NumberInputHelper).init();
    if (window.infinityListiner == undefined)
      window.infinityListiner = (new LoadInfinityHelper());
  },
  initSelectPicker() {
    const selects = $("form.simple_form select, .selectpicker")
      .not(".jselect-picker")
      .removeAttr("required")
      .removeAttr("aria-required")
      .addClass("jselect-picker");

    selects.each(function(index, select) {
      $(select).selectpicker(
        { hideDisabled: true,
          liveSearchNormalize: true,
          liveSearchPlaceholder: 'Nhập từ khóa',
          liveSearchStyle: 'contains',
          noneResultsText: 'Không tìm thấy {0}' });
    });
  },

  initDatePicker(){
    const datePicker = new DateTimePicker();
    $("input.date_picker:not(.jdate, .readonly)").each(function(index){
      datePicker.init(this);
    })
  },

  initShowPassword(){
    $(".sj-password-eye").click(function(ev) {
      var parent = $($(this).parent());
      var userPasswordInput = $('input.password-check');
      parent.toggleClass("show");
      if (parent.hasClass('show'))
        userPasswordInput.attr('type', 'text');
      else
        userPasswordInput.attr('type', 'password');
    });
  },

  initLightBox: () => {
    lightbox.option({
      'resizeDuration': 200,
      'wrapAround': true
    })
    lightbox.init();

    let gallery = new simpleLightbox('.gallery a');
    gallery.on('show.simplelightbox', function () {
      // do something…
    });
  },

  initModal: () => {
    $('#popup_layer_form').on('hidden.bs.modal', function () {
      $('#popup_form').css('display', 'block');
    })
  },

  initHandleCloseModal: () => {
    $('section#popup_form').on('hidden.bs.modal', function () {
      if (window.modalClosedReload){
        window.modalClosedReload = false
        window.location.reload();
      }
    });

    $('a.modal-close').on('click', function(){
      $('#turbo_modal').modal('hide');
    })
  },

  initTooltip: () => {
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    tooltipTriggerList.map(function (tooltipTriggerEl) {
      new bootstrap.Tooltip(tooltipTriggerEl);
      tooltipTriggerEl.addEventListener("click", function() {
        var tooltipInstance = bootstrap.Tooltip.getInstance($(this));
        if (tooltipInstance) {
          tooltipInstance.hide();
        }
      });
    })
  }
}
