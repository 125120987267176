import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    let lat = Number(this.element.dataset.lat);
    let lng = Number(this.element.dataset.lng);
    this.initMap(lat, lng);
  }

  async initMap(lat, lng){
    const myLatLng = { lat: lat, lng: lng };
    const { Map } = await google.maps.importLibrary("maps");
    let map = new Map(document.getElementById("location_building_map"), {
      center: myLatLng,
      zoom: 15,
    });

    new google.maps.Marker({
      position: myLatLng,
      map,
      title: "MiaHouse",
    });
  }
}
