import Toastify from "toastify-js";

export default function ToastHelper(){
  this.succOptions = {
    duration: 3000,
    gravity: "top",
    position: "right",
    escapeMarkup: false,
    close: true
  }
  this.errorOptions = {...this.succOptions, style: { background: '#DF1F00' }};

  this.success = function(text){
    Toastify({...this.succOptions, text: text}).showToast();
  }

  this.error = function(text){
    Toastify({...this.errorOptions, text: text}).showToast();
  }
}




