export default function LoadInfinityHelper(){
  this.init = function(actionLink, listID){
    this.actionLink = actionLink;
    this.listID = listID;
    this.loadmore = true;
    this.loaded = true;
    this.heightFooter = 20;
    this.secondLoad = false;

    window.addEventListener('scroll', ()=> { this.onScroll() }, false);
  }

  this.onScroll = function(){
    if (this.loadmore && this.loaded) {
      const curScrolltop = $(window).scrollTop();
      const lastScrolltop = $(document).height() - $(window).height();
      if ((curScrolltop > lastScrolltop - this.heightFooter - 100 && !this.secondLoad)
          || curScrolltop === lastScrolltop) {
        setTimeout(() => { this.secondLoad = false; }, 1000);

        this.secondLoad = true;
        this.handleLoadMore();
      }
    }
  }

  this.handleLoadMore = function(){
    let self = this;
    let listEml = $(`#${this.listID}`);
    if (listEml.length == 0) return;
    let curPage = Number(listEml.data('pagi').page);
    let totalPages = Number(listEml.data('pagi').total_pages);
    if (curPage == totalPages) return
    this.loaded = false;
    $.ajax({
      url: `${self.actionLink}.js?page=${curPage+1}`,
      method: 'GET'
    }).always(function(){
      self.loaded = true;
      self.loadmore = curPage < totalPages;
    });
  }
}
