import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    let modal = $(this.element);
    if (modal.data('behave') == 'hide'){
      $('#turbo_modal').modal('hide');
      modal.remove();
    }
    else{
      $("body").removeAttr("style");
      modal.modal('show');
      //remove old modal
      $('body > section.popups-theme[aria-hidden=true]').remove();

      var backdrop = $('div.modal-backdrop');
      if (backdrop.length > 1)
        backdrop.first().remove();
    }
  }
}
