import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    let otherUserElm = $('#other_customer_user');

    $('.radio_option_book_for input:radio').change(function(){
      let val = $(this).val();
      if (val == 'your_self')
        otherUserElm.addClass('d-none')
      else
        otherUserElm.removeClass('d-none')
    })
  }
}


