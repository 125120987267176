export default function ApartmentHelper(){
  this.init = function(){
    $('select#select_building_id').on('change', function(){
      var id = $(this).val();
      var selectedAparmentId = $('#select_apartment_id').val();
      var type = $(this).data('type');
      var namespace = $('body').data('namespace');
      $.ajax({
        url: `/${namespace}/buildings/${id}/opt_apartments.js?selected_apartment_id=${selectedAparmentId}&type=${type}`,
        method: 'GET'
      });
    })
  }
}
