import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    this.initActionRenderForms();
    this.initHandleEventShowModal();
  }

  initActionRenderForms(){
    $('section.shared-popups-login').on('click', '.user-register-action', function(){
      $('.render-modal').addClass('hidden');
      var target = $(this).data('urm-target');
      $('.render-modal').each(function(){
        if ($(this).attr('data') === target){
          $(this).removeClass('hidden')
        }
      });
    });
  }

  initHandleEventShowModal(){
    $('#sign_in_up_modal').on('show.bs.modal', function (event) {
      var button = $(event.relatedTarget)
      var recipient = button.data('urTarget')
      var modal = $(this)
      modal.find('.render-modal').addClass('hidden');
      recipient === 'sign-in' ? modal.find('.render-sign-in').removeClass('hidden') : modal.find('.render-sign-up').removeClass('hidden')
    })
  }
}
